import React, { FC, useState, ChangeEvent, useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import { useTransition, animated, config } from 'react-spring'
import debounce from 'awesome-debounce-promise';
import dayjs from 'dayjs'

import SEO from "../components/seo"
import Box from "../components/box"
import ResearchTask from "../components/research-tasks/research-task"
import Search from "../components/form/search"
import Wrapper from "../containers/wrapper"
import { ResearchTaskModel } from "../models/researchTask"
import { useAllPokemon } from "../hooks/useAllPokemon"
import { PokemonNode } from "../models/pokemon";
import { EventNode } from "../models/event";

interface Node {
  node: ResearchTaskModel;
}

interface Props extends PageProps {
  data: {
    allSanityResearchTask: {
      edges: Node[];
    },
    allSanityEvent: {
      edges: EventNode[]
    }
  }
}

const searchTasks = (term: string, tasks: ResearchTaskModel[], pokemon: PokemonNode[]) => {
  const normalizedTerm = term.toLowerCase()
  if (term === 'event') {
    // show tasks linked to events
    return tasks.filter(item => !!item.event)
  }
  const filteredByTaskName = tasks.filter(item =>
    item.name.toLowerCase().includes(normalizedTerm)
  )
  const filteredByRewardName = tasks.filter(item => item.reward.some(reward => pokemon[reward.pokemonId - 1].node.name.toLowerCase().includes(normalizedTerm)))
  if (filteredByTaskName.length) {
    return filteredByTaskName
  } else if (filteredByRewardName.length) {
    return filteredByRewardName
  }
  return []
}

const searchTasksDebounced = debounce(searchTasks, 350);
const sortTasks = (a: ResearchTaskModel, b: ResearchTaskModel) => a.type.localeCompare(b.type)

const ResearchTasks: FC<Props> = ({ data }) => {
  const { allSanityResearchTask: { edges } } = data
  const { allSanityEvent: { edges: eventEdges } } = data

  const [tasks, setTasks] = useState<Array<ResearchTaskModel>>([])
  const [sortedTasks, setSortedTasks] = useState<Array<ResearchTaskModel>>([])
  const pokemon = useAllPokemon()

  useEffect(() => {
    // Regular tasks
    const sorted = edges.map(task => task.node).sort(sortTasks)
    // Event specific tasks
    const nestedEventTasks = eventEdges.map(edge => edge.node)
      .filter(event => dayjs().diff(event.dateStart) > 0 && dayjs().diff(event.dateEnd) < 0)
      .filter(event => event.researchTasks !== undefined)
      .map(({ researchTasks = [], ...event }) =>
        researchTasks.map(task => ({ ...task, event: { ...event } }))
      )
    const eventTasks = Array.prototype.concat.apply([], nestedEventTasks)
    const allTasks = eventTasks.concat(sorted)
    setSortedTasks(allTasks)
    setTasks(allTasks)
  }, [edges])

  const tasksTransitions = useTransition(sortedTasks, {
    config: config.wobbly,
    from: { opacity: 0, transform: "translate3d(0px, 20px, 0px)" },
    enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
    leave: { opacity: 0, transform: "translate3d(0px, -25px, 0px)" },
    keys: sortedTasks.map((item, index) => index)
  });

  const onSearchChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const filteredTasks = await searchTasksDebounced(evt.currentTarget.value, tasks, pokemon)
    setSortedTasks(filteredTasks)
  }

  return (
    <Wrapper>
      <SEO title={`Pokemon Go Research Tasks`} />
      <Search placeholder="Search" onChange={onSearchChange} mb={4} />

      <Box display={{ xs: 'block', sm: 'grid' }} gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' }} gridColumnGap={4}>
        {tasksTransitions((styles, task) => (
          // @ts-ignore
          <animated.div style={styles}>
            <ResearchTask
              key={task.name}
              task={task}
              rewards={task.reward.map(reward => ({ ...pokemon[reward.pokemonId - 1].node, pokemonRef: reward}))}
            />
          </animated.div>
        ))}
      </Box>

    </Wrapper>
  )
}

export const query = graphql`
  query {
    allSanityResearchTask {
      edges {
        node {
          ...ResearchTaskFields
        }
      }
    }
    allSanityEvent(filter: {researchTasks: {elemMatch: {name: {ne: "undefined"}}}}) {
      edges {
        node {
          researchTasks {
            ...ResearchTaskFields
          }
          name
          dateEnd
          dateStart
          slug {
            current
          }
        }
      }
    }
  }
`

export default ResearchTasks
