import styled from "styled-components";
import { flexbox, FlexboxProps, fontSize, FontSizeProps, space, SpaceProps, variant, VariantArgs } from "styled-system";

interface Props extends SpaceProps, FontSizeProps, FlexboxProps, VariantArgs {
    variant?: 'hidden'
}

const Input = styled.input.attrs<Props>(props => ({
    type: props.type || 'text'
})) <Props>`
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    line-height: 1;

    &[type="text"] {
        appearance: none;
        display: block;
        width: 100%;
        font-size: ${({ theme }) => theme.fontSizes[4]}px;
        padding: ${({ theme }) => theme.space[3]}px ${({ theme }) => theme.space[3]}px;
        background-color: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => theme.colors.grey[2]};
        border-radius: ${({ theme }) => theme.radii.small}px;
        box-shadow: none;
    }

    ${space}
    ${fontSize}
    ${flexbox}

    ${variant({
    variants: {
        hidden: {
            display: 'none'
        }
    }
})}
`

export default Input