import React, { FC } from 'react'

import { capitalize } from '../../helpers/helpers';
import { Pokemon, PokemonReference } from '../../models/pokemon';
import { ResearchTaskModel, getResearchRewardColor } from '../../models/researchTask';
import Box from '../box';

import Card from '../card';
import Link from '../link';
import Heading from '../heading';
import Text from '../text';
import PokemonPreview from '../pokemon/pokemon-preview';

interface RewardPokemon extends Pokemon {
    pokemonRef: PokemonReference;
}

interface Props {
    task: ResearchTaskModel;
    rewards: RewardPokemon[];
}

const ResearchTask: FC<Props> = ({ task, rewards }) => {
    const rewardColor = getResearchRewardColor(task.type, !!task.event)
    const isSingleReward = rewards.length === 1
    return (
        <Card variant="flat" borderColor={task.event ? rewardColor : "grey.2"} p={isSingleReward ? 0 : 3} pb={isSingleReward ? 0 : 8} mb={3} key={task.name} position="relative">
            <Box display={isSingleReward ? 'flex' : 'block'} justifyContent="space-between">
                <Box display="flex" flexDirection="column" justifyContent="center" p={isSingleReward ? 3 : 0} mb={isSingleReward ? 0 : 4} ml={isSingleReward ? 2 : 0} flex="1">
                    <Heading as="h2" fontSize={3} m={0}>
                        {task.event ? <Link to={`/event/${task.event.slug.current}`} fontSize="inherit">{task.name}</Link> : task.name}
                    </Heading>
                    {isSingleReward && <Text fontSize={2} m={0} color="grey.5">Reward: {rewards?.[0].name}</Text>}
                </Box>

                <Box display="flex" order={isSingleReward ? -1 : 1}>
                    {rewards.map(reward => (
                        <PokemonPreview
                            key={reward.name}
                            pokemonRef={reward.pokemonRef}
                            imageSize="60px"
                            iconSize="12px"
                            mr={isSingleReward ? 0 : 3}
                            hideName={isSingleReward}
                            borderRadius={isSingleReward ? 'none' : 'medium'}
                            />
                    ))}
                </Box>
            </Box>

            <Box
                py={2}
                px={3}
                borderTopLeftRadius="medium"
                backgroundColor={rewardColor}
                display="inline-block"
                position="absolute"
                right="0"
                bottom="0"
            >
                <Text color="whiteAlways" fontSize={1} p={0} m={0}>
                    {task.event ? `Event: ${task.event.name}` : capitalize(task.type)}
                </Text>
            </Box>
        </Card>
    )
}

export default ResearchTask;