import { graphql } from "gatsby";
import { Event } from "./event";
import { PokemonReference } from "./pokemon";

export enum ResearchTaskType {
    Catching = "catching",
    Battling = "battling",
    Throwing = "throwing",
    BuddyFriendship = "buddyFriendship",
    Misc = "misc",
}

interface ResearchTaskModel {
    name: string;
    type: ResearchTaskType;
    reward: PokemonReference[];
    event?: {
        name: string;
        dateStart: string;
        dateEnd: string;
        slug: {
            current: string;
        }
    };
}

export const getResearchRewardColor = (type: ResearchTaskType, isEvent?: boolean) => {
    let color;
    if (isEvent) {
        return '#F9A825'
    }
    switch(type) {
        case ResearchTaskType.Battling:
            color = 'red';
            break;
        case ResearchTaskType.BuddyFriendship:
            color = '#AD1457';
            break;
        case ResearchTaskType.Catching:
            color = '#1565C0';
            break;
        case ResearchTaskType.Misc:
            color = '#EF6C00';
            break;
        case ResearchTaskType.Throwing:
            color = '#00695C';
            break;
    }
    return color;
}

export const RESEARCH_TASK_CORE_FIELDS = graphql`
    fragment ResearchTaskFields on SanityResearchTask {
        name
        type
        reward {
            pokemonId
            shiny
            form
            costume
        }
    }
`

export { ResearchTaskModel }